<template>
  <ScrollToTop />
</template>

<script>
import ScrollToTop from '@/components/common/ScrollToTop.vue';

export default {
  name: 'FooterSection',
  components: {
    ScrollToTop,
  },
};
</script>

